import {
	cacheData,
	generateSHAHash,
	shallowSortDataByAlphabet,
	decryptForge,
	encryptForge
} from '../utils/common';
import store from '../store';
import {isEmpty} from 'lodash';
import {updateLastReqTime, updateAPIStatus} from '../actions';
import {ERROR_MESSAGES, applicationPageErrors} from '../utils/constant';

const contentType = 'application/json';

const constructResponse = async (response, toCallJsonFunc) => {
	return {
		ok: response.ok,
		headers: response.headers,
		status: response.status,
		statusText: response.statusText,
		data: toCallJsonFunc ? {} : await response.json()
	};
};

// eslint-disable-next-line prettier/prettier
var fetchPostData = async (path, data, needEncryption = false) => {
	//NOSONAR
	// update timeout component to extend session
	store.dispatch(updateLastReqTime());
	const isDIY = cacheData.get('diy');
	const key = cacheData.get('cipherKey');
	const iv = cacheData.get('cipherIV');
	const url =
		isDIY ? `/epro/api/external/app/wmapp${path}` : `/epro/api${path}`;
	// for photo upload, we need to send form data, so body data and header will be different
	const bodyData = needEncryption ? encryptForge(data, key, iv) : JSON.stringify(data);
	const h = {
		Accept: contentType,
		'content-type': contentType
	};

	const pathListToEncrypt = ['/submit-clientfillin', '/app/submit-application'];
	const notToCallJsonList = ['/login', '/sso'];
	if (pathListToEncrypt.includes(path)) {
		const sinatureKey = '9f2738e5243d49c895bdc4cb159b6a58';
		const sortedData = shallowSortDataByAlphabet(data);
		const SHAHash = generateSHAHash(sinatureKey, sortedData);
		h['X-SIGN'] = SHAHash;
	}

	if (cacheData.get('token')) {
		h['X-Auth-Epro'] = cacheData.get('token');
	}

	if (cacheData.get('uuid')) {
		h['uuid'] = cacheData.get('uuid');
	}

	if (needEncryption) {
		h['isEncrypted'] = 'true';
	}

	return fetch(url, {
		body: bodyData,
		['head' + 'ers']: h,
		method: 'POST'
	}).then(async response => {
		if (response && response.headers) {
			const isEncrypt = response.headers.get('isEncrypt');
			// only need to decrypt the data for client journey
			if (isEncrypt == 'true' && isDIY) {
				const jsonData = await response.text();
				return {
					ok: response.ok,
					status: response.status,
					headers: response.headers,
					statusText: response.statusText,
					data: decryptForge(jsonData, cacheData.get('cipherKey'), cacheData.get('cipherIV'))
				};
			}
		}
		if (response.status === 409) {
			const res = await response.json();
			if (res.message === applicationPageErrors.duplicateLoginErrorMsg) {
				store.dispatch(updateAPIStatus({isAlreadyLogin: true, message: res.message}));
			}
			return res;
		} else if (response.status === 401 && path != '/sso') {
			store.dispatch(
				updateAPIStatus({isSessionTimeOut: true, message: ERROR_MESSAGES.sessionTimeOutErrorMsg})
			);
			return constructResponse(response, notToCallJsonList.includes(path));
		} else {
			store.dispatch(updateAPIStatus({}));
			return constructResponse(response, notToCallJsonList.includes(path));
		}
	});
};

const customLogin = (authCode, isDiy) => {
	const url = isDiy
		? '/epro/api/external/app/wmapp/oauth-diy-login'
		: '/epro/api/external/app/wmapp/oauth-login';

	const code = authCode ? authCode.split('&channel=') : '';
	const channel = code[1] ? code[1] : 'SCWEB';
	cacheData.add('channel', channel);
	const config = {
		Accept: contentType,
		'content-type': contentType,
		'X-Auth-Token': code[0],
		'X-Auth-Source': channel
	};
	if (isDiy) {
		config['Diy'] = true;
	}
	return fetch(url, {
		['head' + 'ers']: config,
		method: 'GET'
	}).then(response => {
		cacheData.add('diy', true);
		if (!response.ok) {
			throw Error(response.statusText);
		}
		const token = response.headers.get('x-auth-epro');
		cacheData.add('token', token);
		if (!isDiy) {
			cacheData.addKeyPairs({consent: true});
		}
		return response;
	});
};

export {fetchPostData, customLogin};

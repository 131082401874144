import {UPDATE_PROGRESS} from '../actions/ActionType';

const initialState = {
	status: 0
};

const progressReducer = (state = initialState, {type, payload}) => {
	switch (type) {
		case UPDATE_PROGRESS: {
			return {...state, status: payload};
		}
		default:
			return state;
	}
};

export default progressReducer;

import {
	UPDATE_ERRORMESSAGS,
	UPDATE_BMINTERVIEW,
	UPDATE_STEP,
	APPLICATION_DATA_LOADED,
	RESET_APPLICATION_REDUCER,
	SELECT_ACCOUNT,
	SELECT_RADIOGROUP,
	INPUT_CHANGE,
	TOGGLE_APPLICANT_JOB_POPUP_PAGE,
	TOGGLE_INSURED_JOB_POPUP_PAGE,
	TOGGLE_COUNTRY_POPUP_PAGE,
	UPDATE_SOURCE_OF_INCOME,
	UPDATE_OCCUPATION,
	UPDATE_UNDERWRITING_ITEM,
	UPDATE_UNDERWRITING_OPTIONS,
	UPDATE_UNDER_WRITING_FIELD,
	UPDATE_APPLICATION_SUBMITTED_STATUS,
	UPDATE_APPLICATION_ILP_PERCENTAGE,
	UPDATE_BENEFICIARY_OPTION,
	UPDATE_BENEFICIARY_LIST,
	UPDATE_BENEFICIARY_ERROR,
	UPDATE_APPLICATION_INCOME_STATUS,
	UPDATE_APPLICATION_EDITABLE_LIST,
	UPDATE_INPUT_ERROR_STATUS,
	UPDATE_APPLICATION_FORM_COMPLETION_STATUS
} from './ActionType';

const updateBmInterview = payload => {
	return {
		type: UPDATE_BMINTERVIEW,
		...payload
	};
};

const updateErrorMessage = payload => {
	return {
		type: UPDATE_ERRORMESSAGS,
		...payload
	};
};

const updateStep = payload => {
	return {
		type: UPDATE_STEP,
		...payload
	};
};

const initiateApplicationData = payload => {
	return {
		type: APPLICATION_DATA_LOADED,
		...payload
	};
};

const resetApplicationReducer = () => {
	return {
		type: RESET_APPLICATION_REDUCER
	};
};

const updateApplicationAccount = payload => {
	return {
		type: SELECT_ACCOUNT,
		...payload
	};
};

const updateRadioGroupData = payload => {
	return {
		type: SELECT_RADIOGROUP,
		...payload
	};
};

const updateOccupation = payload => {
	return {
		type: UPDATE_OCCUPATION,
		...payload
	};
};

const onInputChange = payload => {
	return {
		type: INPUT_CHANGE,
		...payload
	};
};

const updateEditableList = data => {
	return {
		type: UPDATE_APPLICATION_EDITABLE_LIST,
		payload: data
	};
};

const onToggleApplicantJobPopUpPage = () => {
	return {
		type: TOGGLE_APPLICANT_JOB_POPUP_PAGE
	};
};

const onToggleInsuredJobPopUpPage = () => {
	return {
		type: TOGGLE_INSURED_JOB_POPUP_PAGE
	};
};

const onToggleCountryPopUpPage = () => {
	return {
		type: TOGGLE_COUNTRY_POPUP_PAGE
	};
};

const updateIncome = payload => {
	return {
		type: UPDATE_SOURCE_OF_INCOME,
		...payload
	};
};

const updateCustomerUnderwriting = (payload, isApplicant) => {
	return {
		type: UPDATE_UNDERWRITING_ITEM,
		...payload,
		isApplicant
	};
};

const updateUnderwrittingOptions = (payload, isApplicant) => {
	return {
		type: UPDATE_UNDERWRITING_OPTIONS,
		...payload,
		isApplicant
	};
};

const updateFormValidationData = (path, value, isApplicant) => {
	return {
		type: UPDATE_UNDER_WRITING_FIELD,
		path,
		value,
		isApplicant
	};
};

const updateApplicatonSubmittedStatus = value => {
	return {
		type: UPDATE_APPLICATION_SUBMITTED_STATUS,
		submittedStatus: value
	};
};

const updateApplicationIncomeStatus = value => {
	return {
		type: UPDATE_APPLICATION_INCOME_STATUS,
		incomeStatus: value
	};
};

const updateApplicatonILPPercentage = ilpFundAllocation => {
	return {
		type: UPDATE_APPLICATION_ILP_PERCENTAGE,
		ilpFundAllocation
	};
};

const updateBeneficiaryOption = deathPayment => {
	return {
		type: UPDATE_BENEFICIARY_OPTION,
		deathPayment
	};
};

const updateBeneficiary = beneficiaries => {
	return {
		type: UPDATE_BENEFICIARY_LIST,
		beneficiaries
	};
};

const updateBeneficiaryError = beneficiaryError => {
	return {
		type: UPDATE_BENEFICIARY_ERROR,
		beneficiaryError
	};
};

const updateInputErrorStatus = value => {
	return {
		type: UPDATE_INPUT_ERROR_STATUS,
		inputErrorStatus: value
	};
};

const updateApplicationFormCompletionStatus = value => {
	return {
		type: UPDATE_APPLICATION_FORM_COMPLETION_STATUS,
		formCompletionStatus: value
	};
};

export {
	updateBmInterview,
	updateErrorMessage,
	updateStep,
	initiateApplicationData,
	resetApplicationReducer,
	updateApplicationAccount,
	updateRadioGroupData,
	updateOccupation,
	onInputChange,
	onToggleApplicantJobPopUpPage,
	updateIncome,
	updateCustomerUnderwriting,
	updateUnderwrittingOptions,
	updateFormValidationData,
	updateApplicatonSubmittedStatus,
	updateApplicatonILPPercentage,
	updateBeneficiaryOption,
	updateBeneficiary,
	updateBeneficiaryError,
	updateApplicationIncomeStatus,
	onToggleCountryPopUpPage,
	onToggleInsuredJobPopUpPage,
	updateEditableList,
	updateInputErrorStatus,
	updateApplicationFormCompletionStatus
};

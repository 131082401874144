import {
	RESET_BENEFIT_INPUT_REDUCER,
	RESET_BENEFIT_OUTPUT_REDUCER,
	RESET_FNA_REDUCER,
	RESET_HOME_PAGE_REDUCER,
	RESET_PRODUCT_REDUCER,
	RESET_CONSENT_DATA,
	RESET_TIMEOUT_REDUCER
} from './ActionType';

const resetBenefitInputReducer = () => {
	return {
		type: RESET_BENEFIT_INPUT_REDUCER
	};
};

const resetBenefitOutputReducer = () => {
	return {
		type: RESET_BENEFIT_OUTPUT_REDUCER
	};
};

const resetFnaReducer = () => {
	return {
		type: RESET_FNA_REDUCER
	};
};

const resetHomePageReducer = () => {
	return {
		type: RESET_HOME_PAGE_REDUCER
	};
};

const resetProductReducer = () => {
	return {
		type: RESET_PRODUCT_REDUCER
	};
};

const resetConsentReducer = () => {
	return {
		type: RESET_CONSENT_DATA
	};
};

const resetTimeoutReducer = () => {
	return {
		type: RESET_TIMEOUT_REDUCER
	};
};

export {
	resetBenefitInputReducer,
	resetFnaReducer,
	resetHomePageReducer,
	resetProductReducer,
	resetBenefitOutputReducer,
	resetConsentReducer,
	resetTimeoutReducer
};

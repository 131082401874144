import {UPDATE_LAST_REQ_TIME, UPDATE_API_STATUS} from './ActionType';

const updateLastReqTime = () => {
	return {
		type: UPDATE_LAST_REQ_TIME
	};
};

const updateAPIStatus = payload => {
	return {
		type: UPDATE_API_STATUS,
		payload
	};
};

export default updateLastReqTime;

export {updateAPIStatus};

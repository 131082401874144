import {
	UPDATE_PRODUCT_SELECTION,
	RESET_PRODUCT_REDUCER,
	UPDATE_PRODUCT_GROUP_LIST,
	UPDATE_ILP_TOOLTIP_MESSAGE,
	UPDATE_ENABLE_DIY
} from '../actions/ActionType';

const initialState = {
	selectedProduct: {},
	productGroupList: [],
	ilpTooltipMessage: null,
	enableDiy: true
};

const productReducer = (state = initialState, {type, payload}) => {
	switch (type) {
		case UPDATE_ILP_TOOLTIP_MESSAGE: {
			return {...state, ilpTooltipMessage: payload};
		}
		case UPDATE_ENABLE_DIY: {
			return {...state, enableDiy: payload};
		}
		case UPDATE_PRODUCT_SELECTION: {
			return {...state, selectedProduct: payload};
		}
		case RESET_PRODUCT_REDUCER: {
			return initialState;
		}
		case UPDATE_PRODUCT_GROUP_LIST: {
			return {...state, productGroupList: payload};
		}
		default:
			return state;
	}
};

export default productReducer;

import {
	UPDATE_LAST_REQ_TIME,
	UPDATE_API_STATUS,
	UPDATE_PROGRESS,
	HOME_PAGE_UPDATE_ELIGIBILITY_RESULT,
	RESET_TIMEOUT_REDUCER
} from '../actions/ActionType';
const initialState = {
	lastRequest: null,
	loginStatus: {}
};

const timeoutReducer = (state = initialState, {type, payload}) => {
	switch (type) {
		case UPDATE_LAST_REQ_TIME:
		case UPDATE_PROGRESS:
		case HOME_PAGE_UPDATE_ELIGIBILITY_RESULT:
			return {
				...state,
				lastRequest: +new Date()
			};
		case UPDATE_API_STATUS: {
			return {
				...state,
				loginStatus: payload
			};
		}
		case RESET_TIMEOUT_REDUCER: {
			return initialState;
		}
		default:
			return state;
	}
};
export default timeoutReducer;

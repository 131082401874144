import {
	ADD_APPLICATION_TO_REDUCER,
	ADD_TERMS_TO_REDUCER,
	ADD_INSURED_TERMS_TO_REDUCER,
	ADD_TERMS_CONTENT_TO_REDUCER,
	ADD_PHOTOS_TO_REDUCER,
	ADD_INSURED_PHOTOS_TO_REDUCER,
	UPDATE_CONSENT_PHOTO_UPLOADED,
	UPDATE_CONSENT_INSURED_PHOTO_UPLOADED,
	RESET_CONSENT_DATA,
	UPDATE_INSURED_E_SIGNATURE,
	UPDATE_IS_INSURED_VIEWING_CONSENT,
	UPDATE_APPLICANT_TNC_CHECKBOX,
	UPDATE_APPLICANT_TNC_LINKVIEW,
	ADD_ORDER_TO_REDUCER,
	UPDATE_EPRO_FLOW_MODE,
	ADD_FINANCIAL_UNDERWRITING_TO_REDUCER,
	UPDATE_MULTI_FACTOR_AUTHENTICATION,
	UPDATE_APPLICANT_E_SIGNATURE,
	ADD_BENEFICIARY_PHOTOS_TO_REDUCER,
	UPDATE_CONSENT_BENEFICIARY_PHOTO_UPLOADED
} from '../actions/ActionType';

const initialState = {
	application: {},
	termConditions: {},
	insuredTermConditions: {},
	termConditionsContent: {},
	photos: {},
	insuredPhotos: {},
	beneficiaryPhotos: [],
	isPhotoUploaded: false,
	isInsuredPhotoUploaded: false,
	insuredESignature: '',
	applicantESignature: '',
	applicantTNCCheckbox: [],
	applicantTNCLinkView: [],
	isInsuredViewingConsent: false,
	order: {},
	multiFactorAuthentication: {},
	isRMConsent: false,
	beneficiaryUploadStatus: []
};

const CustomerConsentReducer = (state = initialState, {type, payload}) => {
	switch (type) {
		case ADD_APPLICATION_TO_REDUCER: {
			return {...state, application: payload};
		}
		case ADD_FINANCIAL_UNDERWRITING_TO_REDUCER: {
			return {...state, financialUnderwriting: payload};
		}
		case ADD_TERMS_TO_REDUCER: {
			return {...state, termConditions: payload};
		}
		case ADD_INSURED_TERMS_TO_REDUCER: {
			return {...state, insuredTermConditions: payload};
		}
		case ADD_TERMS_CONTENT_TO_REDUCER: {
			return {
				...state,
				termConditionsContent: {...state.termConditionsContent, ...payload}
			};
		}
		case ADD_PHOTOS_TO_REDUCER: {
			return {...state, photos: {...state.photos, ...payload}};
		}
		case ADD_INSURED_PHOTOS_TO_REDUCER: {
			return {...state, insuredPhotos: {...state.insuredPhotos, ...payload}};
		}
		case ADD_BENEFICIARY_PHOTOS_TO_REDUCER: {
			if (payload) {
				let beneficiary = state.beneficiaryPhotos;
				beneficiary[payload.index] = {...state.beneficiaryPhotos[payload.index], ...payload.photos};
				return {...state, beneficiaryPhotos: beneficiary};
			} else {
				return {...state};
			}
		}
		case UPDATE_CONSENT_PHOTO_UPLOADED: {
			return {...state, isPhotoUploaded: payload};
		}
		case UPDATE_CONSENT_INSURED_PHOTO_UPLOADED: {
			return {...state, isInsuredPhotoUploaded: payload};
		}
		case UPDATE_CONSENT_BENEFICIARY_PHOTO_UPLOADED: {
			if (payload) {
				let beneficiaryUpload = state.beneficiaryUploadStatus;
				beneficiaryUpload[payload.index] = payload.status;
				return {...state, beneficiaryUploadStatus: beneficiaryUpload};
			} else {
				return {...state};
			}
		}
		case RESET_CONSENT_DATA: {
			return initialState;
		}
		case UPDATE_INSURED_E_SIGNATURE: {
			return {...state, insuredESignature: payload};
		}
		case UPDATE_APPLICANT_E_SIGNATURE: {
			return {...state, applicantESignature: payload};
		}
		case UPDATE_IS_INSURED_VIEWING_CONSENT: {
			return {...state, isInsuredViewingConsent: payload};
		}
		case UPDATE_APPLICANT_TNC_CHECKBOX: {
			return {...state, applicantTNCCheckbox: payload};
		}
		case UPDATE_APPLICANT_TNC_LINKVIEW: {
			return {...state, applicantTNCLinkView: payload};
		}
		case ADD_ORDER_TO_REDUCER: {
			return {...state, order: payload};
		}
		case UPDATE_EPRO_FLOW_MODE: {
			return {...state, isRMConsent: payload};
		}
		case UPDATE_MULTI_FACTOR_AUTHENTICATION: {
			return {...state, multiFactorAuthentication: payload};
		}
		default:
			return state;
	}
};

export default CustomerConsentReducer;

// progress action type
const UPDATE_PROGRESS = 'UPDATE_PROGRESS';

// mobile app version type
const ADD_MOBILE_APP_VERSION = 'ADD_MOBILE_APP_VERSION';

// FNA action types
const FNA_INITIATE_REDUCER = 'FNA_INITIATE_REDUCER';
const FNA_UPDATE_EDUCATION = 'FNA_UPDATE_EDUCATION';
const FNA_UPDATE_EEA = 'FNA_UPDATE_EEA';
const FNA_UPDATE_CITY = 'FNA_UPDATE_CITY';
const FNA_UPDATE_BRANCH = 'FNA_UPDATE_BRANCH';
const FNA_UPDATE_NET_WEALTH = 'FNA_UPDATE_NET_WEALTH';
const FNA_UPDATE_FAMILY_INCOME = 'FNA_UPDATE_FAMILY_INCOME';
const FNA_UPDATE_PREMIUM_BUDGET = 'FNA_UPDATE_PREMIUM_BUDGET';
const FNA_UPDATE_NET_LIQUID_ASSET = 'FNA_UPDATE_NET_LIQUID_ASSET';
const FNA_UPDATE_DEPENDS_SAVING = 'FNA_UPDATE_DEPENDS_SAVING';
const FNA_UPDATE_SUGGESTIONS = 'FNA_UPDATE_SUGGESTIONS';
const FNA_UPDATE_INSURANCE_TYPES = 'FNA_UPDATE_INSURANCE_TYPES';
const FNA_UPDATE_WEALTH_INSURANCES = 'FNA_UPDATE_WEALTH_INSURANCES';
const FNA_UPDATE_WEALTH_INSURANCES_RANGE = 'FNA_UPDATE_WEALTH_INSURANCES_RANGE';
const FNA_UPDATE_WEALTH_PRESERVE_CAPITAL = 'FNA_UPDATE_WEALTH_PRESERVE_CAPITAL';
const FNA_UPDATE_SUBMITTED = 'FNA_UPDATE_SUBMITTED';
const FNA_UPDATE_REFARMCODE = 'FNA_UPDATE_REFARMCODE';
const FNA_UPDATE_PAGE_LOADED = 'FNA_UPDATE_PAGE_LOADED';

// home page action types
const HOME_PAGE_UPDATE_TYPE = 'HOME_PAGE_UPDATE_TYPE';
const HOME_PAGE_UPDATE_RM_INFO = 'HOME_PAGE_UPDATE_RM_INFO';
const HOME_PAGE_UPDATE_BRANCH_LIST = 'HOME_PAGE_UPDATE_BRANCH_LIST';
const HOME_PAGE_UPDATE_ELIGIBILITY_RESULT = 'HOME_PAGE_UPDATE_ELIGIBILITY_RESULT';
const RESET_TIMEOUT_REDUCER = 'RESET_TIMEOUT_REDUCER';

// reset reducer action types
const RESET_BENEFIT_INPUT_REDUCER = 'RESET_BENEFIT_INPUT_REDUCER';
const RESET_BENEFIT_OUTPUT_REDUCER = 'RESET_BENEFIT_OUTPUT_REDUCER';
const RESET_FNA_REDUCER = 'RESET_FNA_REDUCER';
const RESET_HOME_PAGE_REDUCER = 'RESET_HOME_PAGE_REDUCER';
const RESET_PRODUCT_REDUCER = 'RESET_PRODUCT_REDUCER';
const RESET_APPLICATION_REDUCER = 'RESET_APPLICATION_REDUCER';
const RESET_FINANCIAL_UNDERWRITING_REDUCER = 'RESET_FINANCIAL_UNDERWRITING_REDUCER';

// product action types
const UPDATE_PRODUCT_GROUP_LIST = 'UPDATE_PRODUCT_GROUP_LIST';
const UPDATE_PRODUCT_SELECTION = 'UPDATE_PRODUCT_SELECTION';
const UPDATE_ILP_TOOLTIP_MESSAGE = 'UPDATE_ILP_TOOLTIP_MESSAGE';
const UPDATE_ENABLE_DIY = 'UPDATE_ENABLE_DIY';

// BI action types
const UPDATE_BI_OUTPUT_DATA = 'UPDATE_BI_OUTPUT_DATA';
const UPDATE_BI_INPUT_DATA = 'UPDATE_BI_INPUT_DATA';
const UPDATE_INSURED_DATE_OF_BIRTH = 'UPDATE_INSURED_DATE_OF_BIRTH';
const UPDATE_INSURED_GENDER = 'UPDATE_INSURED_GENDER';
const UPDATE_INSURED_RELATIONSHIP = 'UPDATE_INSURED_RELATIONSHIP';
const UPDATE_INSURED_NAME = 'UPDATE_INSURED_NAME';
const UPDATE_RELATIONSHIP_LIST = 'UPDATE_RELATIONSHIP_LIST';
const ADD_CUSTOMER_BI_APPLICATION_FORM = 'ADD_CUSTOMER_BI_APPLICATION_FORM';
const UPDATE_BI_INPUT_ERROR = 'UPDATE_BI_INPUT_ERROR';

// application/underwritting action types
const UPDATE_ERRORMESSAGS = 'UPDATE_ERRORMESSAGS';
const UPDATE_BMINTERVIEW = 'UPDATE_BMINTERVIEW';
const UPDATE_UNDERWRITING_OPTIONS = 'UPDATE_UNDERWRITING_OPTIONS';
const UPDATE_UNDERWRITING_ITEM = 'UPDATE_UNDERWRITING_ITEM';
const UPDATE_STEP = 'UPDATE_STEP';
const UPDATE_OCCUPATION = 'UPDATE_OCCUPATION';
const UPDATE_SOURCE_OF_INCOME = 'UPDATE_SOURCE_OF_INCOME';
const INPUT_CHANGE = 'INPUT_CHANGE';
const TOGGLE_APPLICANT_JOB_POPUP_PAGE = 'TOGGLE_APPLICANT_JOB_POPUP_PAGE';
const TOGGLE_INSURED_JOB_POPUP_PAGE = 'TOGGLE_INSURED_JOB_POPUP_PAGE';
const TOGGLE_COUNTRY_POPUP_PAGE = 'TOGGLE_COUNTRY_POPUP_PAGE';
const UPDATE_COUNTRY = 'UPDATE_COUNTRY';
const SELECT_RADIOGROUP = 'SELECT_RADIOGROUP';
const SELECT_ACCOUNT = 'SELECT_ACCOUNT';
const APPLICATION_DATA_LOADED = 'APPLICATION_DATA_LOADED';
const UPDATE_UNDER_WRITING_FIELD = 'UPDATE_UNDER_WRITING_FIELD';
const UPDATE_APPLICATION_SUBMITTED_STATUS = 'UPDATE_APPLICATION_SUBMITTED_STATUS';
const UPDATE_APPLICATION_ILP_PERCENTAGE = 'UPDATE_APPLICATION_ILP_PERCENTAGE';
const UPDATE_BENEFICIARY_OPTION = 'UPDATE_BENEFICIARY_OPTION';
const UPDATE_BENEFICIARY_LIST = 'UPDATE_BENEFICIARY_LIST';
const UPDATE_BENEFICIARY_ERROR = 'UPDATE_BENEFICIARY_ERROR';
const UPDATE_APPLICATION_INCOME_STATUS = 'UPDATE_APPLICATION_INCOME_STATUS';
const UPDATE_APPLICATION_EDITABLE_LIST = 'UPDATE_APPLICATION_EDITABLE_LIST';
const UPDATE_INPUT_ERROR_STATUS = 'UPDATE_INPUT_ERROR_STATUS';
const UPDATE_APPLICATION_FORM_COMPLETION_STATUS = 'UPDATE_APPLICATION_FORM_COMPLETION_STATUS';
// session timeout

const UPDATE_LAST_REQ_TIME = 'UPDATE_LAST_REQ_TIME';
const UPDATE_API_STATUS = 'UPDATE_API_STATUS';

// update customer consent
const ADD_ORDER_TO_REDUCER = 'ADD_ORDER_TO_REDUCER';
const ADD_FINANCIAL_UNDERWRITING_TO_REDUCER = 'ADD_FINANCIAL_UNDERWRITING_TO_REDUCER';
const ADD_APPLICATION_TO_REDUCER = 'ADD_APPLICATION_TO_REDUCER';
const ADD_TERMS_TO_REDUCER = 'ADD_TERMS_TO_REDUCER';
const ADD_INSURED_TERMS_TO_REDUCER = 'ADD_INSURED_TERMS_TO_REDUCER';
const ADD_TERMS_CONTENT_TO_REDUCER = 'ADD_TERMS_CONTENT_TO_REDUCER';
const ADD_PHOTOS_TO_REDUCER = 'ADD_PHOTOS_TO_REDUCER';
const ADD_INSURED_PHOTOS_TO_REDUCER = 'ADD_INSURED_PHOTOS_TO_REDUCER';
const UPDATE_CONSENT_PHOTO_UPLOADED = 'UPDATE_CONSENT_PHOTO_UPLOADED';
const UPDATE_CONSENT_INSURED_PHOTO_UPLOADED = 'UPDATE_CONSENT_INSURED_PHOTO_UPLOADED';
const UPDATE_CONSENT_BENEFICIARY_PHOTO_UPLOADED = 'UPDATE_CONSENT_BENEFICIARY_PHOTO_UPLOADED';
const RESET_CONSENT_DATA = 'RESET_CONSENT_DATA';
const UPDATE_INSURED_E_SIGNATURE = 'UPDATE_INSURED_E_SIGNATURE';
const UPDATE_APPLICANT_E_SIGNATURE = 'UPDATE_APPLICANT_E_SIGNATURE';
const UPDATE_IS_INSURED_VIEWING_CONSENT = 'IS_INSURED_VIEWING_CONSENT';
const UPDATE_APPLICANT_TNC_CHECKBOX = 'UPDATE_APPLICANT_TNC_CHECKBOX';
const UPDATE_APPLICANT_TNC_LINKVIEW = 'UPDATE_APPLICANT_TNC_LINKVIEW';
const UPDATE_FINANCIAL_UNDERWRITING = 'UPDATE_FINANCIAL_UNDERWRITING';
const UPDATE_FINANCIAL_VALIDATION_DATA = 'UPDATE_FINANCIAL_VALIDATION_DATA';
const UPDATE_FINANCIAL_OPTION = 'UPDATE_FINANCIAL_OPTION';
const UPDATE_EPRO_FLOW_MODE = 'UPDATE_EPRO_FLOW_MODE';
const UPDATE_MULTI_FACTOR_AUTHENTICATION = 'UPDATE_MULTI_FACTOR_AUTHENTICATION';
const ADD_BENEFICIARY_PHOTOS_TO_REDUCER = 'ADD_BENEFICIARY_PHOTOS_TO_REDUCER';

export {
	UPDATE_PROGRESS,
	FNA_INITIATE_REDUCER,
	FNA_UPDATE_EDUCATION,
	FNA_UPDATE_NET_WEALTH,
	FNA_UPDATE_FAMILY_INCOME,
	FNA_UPDATE_PREMIUM_BUDGET,
	FNA_UPDATE_NET_LIQUID_ASSET,
	FNA_UPDATE_DEPENDS_SAVING,
	FNA_UPDATE_SUGGESTIONS,
	FNA_UPDATE_INSURANCE_TYPES,
	FNA_UPDATE_WEALTH_INSURANCES,
	FNA_UPDATE_WEALTH_INSURANCES_RANGE,
	FNA_UPDATE_WEALTH_PRESERVE_CAPITAL,
	FNA_UPDATE_REFARMCODE,
	FNA_UPDATE_SUBMITTED,
	FNA_UPDATE_PAGE_LOADED,
	HOME_PAGE_UPDATE_TYPE,
	HOME_PAGE_UPDATE_RM_INFO,
	HOME_PAGE_UPDATE_BRANCH_LIST,
	HOME_PAGE_UPDATE_ELIGIBILITY_RESULT,
	RESET_TIMEOUT_REDUCER,
	UPDATE_PRODUCT_SELECTION,
	UPDATE_ILP_TOOLTIP_MESSAGE,
	UPDATE_ENABLE_DIY,
	UPDATE_BI_INPUT_DATA,
	RESET_BENEFIT_INPUT_REDUCER,
	RESET_BENEFIT_OUTPUT_REDUCER,
	RESET_FNA_REDUCER,
	RESET_HOME_PAGE_REDUCER,
	RESET_PRODUCT_REDUCER,
	UPDATE_PRODUCT_GROUP_LIST,
	UPDATE_BI_OUTPUT_DATA,
	UPDATE_ERRORMESSAGS,
	UPDATE_BMINTERVIEW,
	UPDATE_UNDERWRITING_OPTIONS,
	UPDATE_UNDERWRITING_ITEM,
	UPDATE_STEP,
	UPDATE_OCCUPATION,
	UPDATE_SOURCE_OF_INCOME,
	INPUT_CHANGE,
	TOGGLE_APPLICANT_JOB_POPUP_PAGE,
	SELECT_RADIOGROUP,
	SELECT_ACCOUNT,
	UPDATE_BENEFICIARY_OPTION,
	APPLICATION_DATA_LOADED,
	RESET_APPLICATION_REDUCER,
	UPDATE_UNDER_WRITING_FIELD,
	ADD_APPLICATION_TO_REDUCER,
	ADD_TERMS_TO_REDUCER,
	ADD_INSURED_TERMS_TO_REDUCER,
	ADD_TERMS_CONTENT_TO_REDUCER,
	FNA_UPDATE_CITY,
	FNA_UPDATE_BRANCH,
	FNA_UPDATE_EEA,
	UPDATE_LAST_REQ_TIME,
	ADD_PHOTOS_TO_REDUCER,
	ADD_INSURED_PHOTOS_TO_REDUCER,
	UPDATE_CONSENT_PHOTO_UPLOADED,
	UPDATE_CONSENT_INSURED_PHOTO_UPLOADED,
	UPDATE_APPLICATION_SUBMITTED_STATUS,
	RESET_CONSENT_DATA,
	UPDATE_APPLICATION_ILP_PERCENTAGE,
	UPDATE_INSURED_DATE_OF_BIRTH,
	UPDATE_INSURED_GENDER,
	UPDATE_INSURED_RELATIONSHIP,
	UPDATE_INSURED_NAME,
	UPDATE_RELATIONSHIP_LIST,
	UPDATE_INSURED_E_SIGNATURE,
	UPDATE_APPLICANT_E_SIGNATURE,
	UPDATE_IS_INSURED_VIEWING_CONSENT,
	UPDATE_BENEFICIARY_LIST,
	UPDATE_APPLICANT_TNC_CHECKBOX,
	UPDATE_APPLICANT_TNC_LINKVIEW,
	ADD_ORDER_TO_REDUCER,
	UPDATE_BENEFICIARY_ERROR,
	ADD_FINANCIAL_UNDERWRITING_TO_REDUCER,
	UPDATE_FINANCIAL_UNDERWRITING,
	UPDATE_FINANCIAL_VALIDATION_DATA,
	UPDATE_FINANCIAL_OPTION,
	RESET_FINANCIAL_UNDERWRITING_REDUCER,
	UPDATE_EPRO_FLOW_MODE,
	UPDATE_APPLICATION_INCOME_STATUS,
	UPDATE_API_STATUS,
	UPDATE_MULTI_FACTOR_AUTHENTICATION,
	ADD_MOBILE_APP_VERSION,
	ADD_BENEFICIARY_PHOTOS_TO_REDUCER,
	UPDATE_CONSENT_BENEFICIARY_PHOTO_UPLOADED,
	ADD_CUSTOMER_BI_APPLICATION_FORM,
	TOGGLE_COUNTRY_POPUP_PAGE,
	UPDATE_COUNTRY,
	TOGGLE_INSURED_JOB_POPUP_PAGE,
	UPDATE_APPLICATION_EDITABLE_LIST,
	UPDATE_INPUT_ERROR_STATUS,
	UPDATE_APPLICATION_FORM_COMPLETION_STATUS,
	UPDATE_BI_INPUT_ERROR
};

/* eslint-disable prettier/prettier */
import React, {Suspense, lazy} from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import store from './store';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import {fetchPostData} from './actions/fetchData';
import {cacheData} from './utils/common';
import './index.scss';
const BancaLoader = lazy(() => import('./components/common/bancaLoader'));
const Login = lazy(() => import('./components/login/login'));
const Landing = lazy(() => import('./components/login/landing'));
const DiyLogin = lazy(() => import('./components/login/DiyLogin'));
const ConsentLogin = lazy(() => import('./components/login/consentLogin'));
const HomePage = lazy(() => import('./components/home/homePage'));
const FNAHomePage = lazy(() => import('./components/FNA/fnaHomePage'));
const ProductList = lazy(() => import('./components/products/product-list'));
const BenefitInputHomePage = lazy(() => import('./components/benefit-illustration/input/benefitInputHomePage'));
const BenefitOutputHomePage = lazy(() => import('./components/benefit-illustration/output/benefitOutputHomePage'));
const Application = lazy(() => import('./components/application'));
const ConsentSuccessPage = lazy(() => import('./components/customer-consent/success/successPage'));
const ApplicationSubmitSuccessPage = lazy(() => import('./components/success/index'));
const ErrorPage = lazy(() => import('./components/error'));
const OrdersReview = lazy(() => import('./components/customer-consent/order-review/orders-review'));
const FNAReview = lazy(() => import('./components/customer-consent/FNA-product-review/fnaReview'));
const BIReview = lazy(() => import('./components/customer-consent/benefits/benefitOutput'));
const ApplicationReview = lazy(() => import('./components/customer-consent/application-review/Application'));
const UnderwritingReview = lazy(() => import('./components/customer-consent/underwriting-review/UnderwritingReview'));
const PhotoUpload = lazy(() => import('./components/customer-consent/photos/photoUpload'));
const TermAndConditions = lazy(() => import('./components/customer-consent/tnc/TermAndConditions'));
const ExpireHandler = lazy(() => import('./components/common/ExpireHandler'));
const FinancialUnderWritingHomePage = lazy(() => import('./components/customer-consent/financial-underwriting/financialUnderwritingHomePage'));
const FinancialUnderWritingReview = lazy(() => import('./components/customer-consent/financial-underwriting-review/financialUnderwritingReview'));
const MultiFactorAuthorization = lazy(() => import('./components/customer-consent/multi-factor-authorization'));
const ScreenShot = lazy(() => import('./components/common/ScreenShot'));
const ConsentNotification = lazy(() => import('./components/application/consent-notification'));
const RMFinancialUnderwritingPage = lazy(() => import('./components/application/finance-underwriting/finance-underwriting'));
const container = document.getElementById('root');
const root = createRoot(container);

function RouteWrapper({children, ...rest}) {
	const [role, setRole] = React.useState(0);
	React.useEffect(() => {
		window.scrollTo(0, 0);
		fetchPostData('/user-role', {}, true)
			.then(response => {
				if (response.status && response.status != 200 ) {
					throw Error(response.statusText);
				}
				setRole(1);
			})
			.catch(e => {
				setRole(-1);
			});
	}, []);
	return (
		<Route
			{...rest}
			render={({location}) =>
				role === -1 ? (
					<Redirect
						to={{
							pathname: '/',
							state: {from: location}
						}}
					/>
				) : (
					<>
						<ScreenShot>{children}</ScreenShot>
						<ExpireHandler location={location} />
					</>
				)
			}
		/>
	);
}

function DoNotDisplayInDIY({children}) {
	const isDIY = cacheData.get('diy');
	if (isDIY) {
		return null;
	}
	return children;
}

root.render(
	<Provider store={store}>
		<Router>
			<Suspense fallback={<BancaLoader showLoader={true} />}>
				<Switch>
					<Route
						exact={true}
						path="/sso"
						render={() => (
							<DoNotDisplayInDIY>
								<Landing />
							</DoNotDisplayInDIY>
						)}
					/>
					{/* <Route exact={true} path="/" component={Login} /> */}
					<Route exact={true} path="/special-login" component={Login} />
					<Route exact={true} path="/diy-login/:authCode" component={DiyLogin} />
					<Route exact={true} path="/consent-login/:authCode" component={ConsentLogin} />
					<RouteWrapper exact={true} path="/home">
						<DoNotDisplayInDIY>
							<HomePage />
						</DoNotDisplayInDIY>
					</RouteWrapper>
					<RouteWrapper exact={true} path="/fna">
						<FNAHomePage />
					</RouteWrapper>
					<RouteWrapper exact={true} path="/product">
						<ProductList />
					</RouteWrapper>
					<RouteWrapper exact={true} path="/benefit/input">
						<BenefitInputHomePage />
					</RouteWrapper>
					<RouteWrapper exact={true} path="/benefit/output">
						<BenefitOutputHomePage />
					</RouteWrapper>
					<RouteWrapper exact={true} path="/application">
						<Application />
					</RouteWrapper>
					<RouteWrapper exact={true} path="/notification">
						<ConsentNotification />
					</RouteWrapper>
					<RouteWrapper exact={true} path="/consent/order-review">
						<OrdersReview />
					</RouteWrapper>
					<RouteWrapper exact={true} path="/consent/financial-underwriting/:applicationId">
						<FinancialUnderWritingHomePage />
					</RouteWrapper>
					<RouteWrapper exact={true} path="/consent/financial-underwriting-review/:applicationId">
						<FinancialUnderWritingReview />
					</RouteWrapper>
					<RouteWrapper exact={true} path="/consent/fna-review/:applicationId">
						<FNAReview />
					</RouteWrapper>
					<RouteWrapper exact={true} path="/consent/bi-review/:applicationId">
						<BIReview />
					</RouteWrapper>
					<RouteWrapper exact={true} path="/consent/application-review/:applicationId">
						<ApplicationReview />
					</RouteWrapper>
					<RouteWrapper exact={true} path="/consent/underwriting-review/:applicationId">
						<UnderwritingReview />
					</RouteWrapper>
					<RouteWrapper exact={true} path="/consent/photo-upload/:applicationId">
						<PhotoUpload />
					</RouteWrapper>
					<RouteWrapper exact={true} path="/consent/termConditions/:applicationId">
						<TermAndConditions />
					</RouteWrapper>
					<RouteWrapper exact={true} path="/consent/rm-financial-underwriting/:applicationId">
						<RMFinancialUnderwritingPage />
					</RouteWrapper>
					<RouteWrapper exact={true} path="/consent/multi-factor-authentication/:applicationId">
						<MultiFactorAuthorization />
					</RouteWrapper>
					<RouteWrapper exact={true} path="/consent/success">
						<ConsentSuccessPage />
					</RouteWrapper>
					<RouteWrapper exact={true} path="/success">
						<ApplicationSubmitSuccessPage />
					</RouteWrapper>
					<Route component={ErrorPage} />
				</Switch>
			</Suspense>
		</Router>
	</Provider>
);
